<template>
	<div class="rccdevelop">
		<div class="title">
			<h2>
				<!-- 中国红十字会发展历程 -->
				{{ rccdevelop.title || '' }}
			</h2>
			<TitleLine width="220px"></TitleLine>
		</div>
		<div class="motion-origin">
			<div class="timeline">
				<div class="timespot1"><span>{{yearList[0]}}</span></div>
				<div class="timespot2"><span>{{yearList[1]}}</span></div>
				<div class="timespot3"><span>{{yearList[2]}}</span></div>
				<div class="timespot4"><span>{{yearList[3]}}</span></div>
				<div class="timespot5"><span>{{yearList[4]}}</span></div>
				<div class="timespot6"><span>{{yearList[5]}}</span></div>
			</div>
			<div class="motion-content">
				<p v-html="rccdevelop.text ? rccdevelop.text : ''"></p>
			</div>
		</div>
	</div>
</template>

<script>
import apiUrls from '@api';
import TitleLine from '@/components/TitleLine';
export default {
	components: {
		TitleLine
	},
	name: 'Rccdevelop',
	data() {
		return {
			rccdevelop: {},
			yearList: []
		};
	},
	created() {
		this.getrccdevelop();
	},
	methods: {
		getrccdevelop() {
			apiUrls.getMotion({ node: 'zghszhfzlc' }).then(res => {
				// console.log(res.results.data.yearlist)
				this.rccdevelop = res.results.data;
				this.yearList = res.results.data.yearlist.substr(1, res.results.data.yearlist.length-2).split(',')
				// console.log(this.yearList)
			});
		}
	}
};
</script>

<style lang="less" scoped>
.rccdevelop {
	.title {
		font-size: 23px;
		color: #333333;
		font-weight: bolder;
		text-align: center;
		overflow: hidden;
		h2 {
			margin-top: 41px;
			margin-bottom: 19px;
		}
		p {
			margin-bottom: 49px;
		}
	}
	.motion-origin {
		padding-left: 24px;
		display: flex;
		.timeline {
			width: 1px;
			height: 1023px;
			top: 10px;
			background-color: #d1292e;
			position: relative;
			span {
				color: #d1292e;
				font-size: 17px;
				position: absolute;
				font-weight: bold;
				left: -67px;
			}
			.timespot1 {
				width: 16px;
				height: 16px;
				position: absolute;
				top: -1px;
				left: -7px;
				background: url('../../../assets/img/timespot.png') no-repeat center center;
			}
			.timespot2 {
				width: 16px;
				height: 16px;
				position: absolute;
				top: 320px;
				left: -7px;
				background: url('../../../assets/img/timespot.png') no-repeat center center;
			}
			.timespot3 {
				width: 16px;
				height: 16px;
				position: absolute;
				top: 465px;
				left: -7px;
				background: url('../../../assets/img/timespot.png') no-repeat center center;
			}
			.timespot4 {
				width: 16px;
				height: 16px;
				position: absolute;
				top: 682px;
				left: -7px;
				background: url('../../../assets/img/timespot.png') no-repeat center center;
			}
			.timespot5 {
				width: 16px;
				height: 16px;
				position: absolute;
				top: 826px;
				left: -7px;
				background: url('../../../assets/img/timespot.png') no-repeat center center;
			}
			.timespot6 {
				width: 16px;
				height: 16px;
				position: absolute;
				bottom: 0px;
				left: -7px;
				background: url('../../../assets/img/timespot.png') no-repeat center center;
				/* span {
					left: -43px;
				} */
			}
		}
	}
	.motion-content {
		padding-left: 24px;
		p {
			text-indent: 2em;
			font-size: 16px;
			color: #666666;
			line-height: 36px;
			&:nth-child(3) {
				margin-top: 52px;
			}
			&:nth-child(4) {
				margin-top: 51px;
			}
			&:nth-child(5) {
				margin-top: 50px;
			}
			&:nth-child(6),
			&:last-child {
				// margin-top: 52px;
			}
		}
	}
}
</style>
